// import cameraDayUp from '@/assets/map/camera_day_up.svg'
// import cameraDayDown from '@/assets/map/camera_day_down.svg'
// import cameraNightUp from '@/assets/map/camera_night_up.svg'
// import cameraNightDown from '@/assets/map/camera_night_down.svg'

import cameraUp from '@/assets/map/camera_up.svg'
import cameraUpSelected from '@/assets/map/camera_up_selected.svg'
import cameraDown from '@/assets/map/camera_down.svg'
import cameraDownSelected from '@/assets/map/camera_down_selected.svg'

import busDayUp from '@/assets/map/bus_day_up.svg'
import busDayUpSelected from '@/assets/map/bus_day_up_selected.svg'
import busDayDown from '@/assets/map/bus_day_down.svg'
import busDayDownSelected from '@/assets/map/bus_day_down_selected.svg'
import busNightUp from '@/assets/map/bus_night_up.svg'
import busNightUpSelected from '@/assets/map/bus_night_up_selected.svg'
import busNightDown from '@/assets/map/bus_night_down.svg'
import busNightDownSelected from '@/assets/map/bus_night_down_selected.svg'

import taxiDayUp from '@/assets/map/taxi_day_up.svg'
import taxiDayUpSelected from '@/assets/map/taxi_day_up_selected.svg'
import taxiDayDown from '@/assets/map/taxi_day_down.svg'
import taxiDayDownSelected from '@/assets/map/taxi_day_down_selected.svg'
import taxiNightUp from '@/assets/map/taxi_night_up.svg'
import taxiNightUpSelected from '@/assets/map/taxi_night_up_selected.svg'
import taxiNightDown from '@/assets/map/taxi_night_down.svg'
import taxiNightDownSelected from '@/assets/map/taxi_night_down_selected.svg'

import lprDayUp from '@/assets/map/lpr_day_up.svg'
import lprDayUpSeleted from '@/assets/map/lpr_day_up_selected.svg'
import lprDayDown from '@/assets/map/lpr_day_down.svg'
import lprDayDownSelected from '@/assets/map/lpr_day_down_selected.svg'
import lprNightUp from '@/assets/map/lpr_night_up.svg'
import lprNightUpSelected from '@/assets/map/lpr_night_up_selected.svg'
import lprNightDown from '@/assets/map/lpr_night_down.svg'
import lprNightDownSelected from '@/assets/map/lpr_night_down_selected.svg'

import sensorUp from '@/assets/map/sensor_up.svg'
import sensorUpSelected from '@/assets/map/sensor_up_selected.svg'
import sensorDown from '@/assets/map/sensor_down.svg'
import sensorDownSelected from '@/assets/map/sensor_down_selected.svg'

import ambulanciaUp from '@/assets/map/ambulancia_up.svg'
import ambulanciaUpSelected from '@/assets/map/ambulancia_up_selected.svg'
import ambulanciaDown from '@/assets/map/ambulancia_down.svg'
import ambulanciaDownSelected from '@/assets/map/ambulancia_down_selected.svg'
import ayudantiaUp from '@/assets/map/ayudantia_up.svg'
import ayudantiaUpSelected from '@/assets/map/ayudantia_up_selected.svg'
import ayudantiaDown from '@/assets/map/ayudantia_down.svg'
import ayudantiaDownSelected from '@/assets/map/ayudantia_down_selected.svg'
import bomberosUp from '@/assets/map/bombero_up.svg'
import bomberosUpSelected from '@/assets/map/bombero_up_selected.svg'
import bomberosDown from '@/assets/map/bombero_down.svg'
import bomberosDownSelected from '@/assets/map/bombero_down_selected.svg'
import militarUp from '@/assets/map/militar_up.svg'
import militarUpSelected from '@/assets/map/militar_up_selected.svg'
import militarDown from '@/assets/map/militar_down.svg'
import militarDownSelected from '@/assets/map/militar_down_selected.svg'
import policiaMunicipalUp from '@/assets/map/policia_municipal_up.svg'
import policiaMunicipalUpSelected from '@/assets/map/policia_municipal_up_selected.svg'
import policiaMunicipalDown from '@/assets/map/policia_municipal_down.svg'
import policiaMunicipalDownSelected from '@/assets/map/policia_municipal_down_selected.svg'
import patrullaMunicipalUp from '@/assets/map/patrulla_municipal_up.svg'
import patrullaMunicipalUpSelected from '@/assets/map/patrulla_municipal_up_selected.svg'
import patrullaMunicipalDown from '@/assets/map/patrulla_municipal_down.svg'
import patrullaMunicipalDownSelected from '@/assets/map/patrulla_municipal_down_selected.svg'
import policiaEstatalUp from '@/assets/map/policia_estatal_up.svg'
import policiaEstatalUpSelected from '@/assets/map/policia_estatal_up_selected.svg'
import policiaEstatalDown from '@/assets/map/policia_estatal_down.svg'
import policiaEstatalDownSelected from '@/assets/map/policia_estatal_down_selected.svg'
import policiaMinisterialUp from '@/assets/map/policia_ministerial_up.svg'
import policiaMinisterialUpSelected from '@/assets/map/policia_ministerial_up_selected.svg'
import policiaMinisterialDown from '@/assets/map/policia_ministerial_down.svg'
import policiaMinisterialDownSelected from '@/assets/map/policia_ministerial_down_selected.svg'
import patrullaMinisterialUp from '@/assets/map/patrulla_ministerial_up.svg'
import patrullaMinisterialUpSelected from '@/assets/map/patrulla_ministerial_up_selected.svg'
import patrullaMinisterialDown from '@/assets/map/patrulla_ministerial_down.svg'
import patrullaMinisterialDownSelected from '@/assets/map/patrulla_ministerial_down_selected.svg'
import patrullaPcivilUp from '@/assets/map/patrulla_pcivil_up.svg'
import patrullaPcivilUpSelected from '@/assets/map/patrulla_pcivil_up_selected.svg'
import patrullaPcivilDown from '@/assets/map/patrulla_pcivil_down.svg'
import patrullaPcivilDownSelected from '@/assets/map/patrulla_pcivil_down_selected.svg'
import elementoPcivilUp from '@/assets/map/elemento_pc_up.svg'
import elementoPcivilUpSelected from '@/assets/map/elemento_pc_up_selected.svg'
import elementoPcivilDown from '@/assets/map/elemento_pc_down.svg'
import elementoPcivilDownSelected from '@/assets/map/elemento_pc_down_selected.svg'
import patrullaDifUp from '@/assets/map/patrulla_dif_up.svg'
import patrullaDifUpSelected from '@/assets/map/patrulla_dif_up_selected.svg'
import patrullaDifDown from '@/assets/map/patrulla_dif_down.svg'
import patrullaDifDownSelected from '@/assets/map/patrulla_dif_down_selected.svg'

import patrullaStjUp from '@/assets/map/patrulla_stj_up.svg'
import patrullaStjUpSelected from '@/assets/map/patrulla_stj_up_selected.svg'
import patrullaStjDown from '@/assets/map/patrulla_stj_down.svg'
import patrullaStjDownSelected from '@/assets/map/patrulla_stj_down_selected.svg'

import patrullaEstatalUp from '@/assets/map/patrulla_estatal_up.svg'
import patrullaEstatalUpSelected from '@/assets/map/patrulla_estatal_up_selected.svg'
import patrullaEstatalDown from '@/assets/map/patrulla_estatal_down.svg'
import patrullaEstatalDownSelected from '@/assets/map/patrulla_estatal_down_selected.svg'

import patrullaGnacionalUp from '@/assets/map/patrulla_gnacional_up.svg'
import patrullaGnacionalUpSelected from '@/assets/map/patrulla_gnacional_up_selected.svg'
import patrullaGnacionalDown from '@/assets/map/patrulla_gnacional_down.svg'
import patrullaGnacionalDownSelected from '@/assets/map/patrulla_gnacional_down_selected.svg'

import patrullaRecorridosUp from '@/assets/map/patrulla_recorridos_up.png'
import patrullaRecorridosUpSelected from '@/assets/map/patrulla_recorridos_up_selected.png'
import patrullaRecorridosDown from '@/assets/map/patrulla_recorridos_down.png'
import patrullaRecorridosDownSelected from '@/assets/map/patrulla_recorridos_down_selected.png'

import patrullaServiciosEstablecidosUp from '@/assets/map/patrulla_servicios_establecidos_up.png'
import patrullaServiciosEstablecidosUpSelected from '@/assets/map/patrulla_servicios_establecidos_up_selected.png'
import patrullaServiciosEstablecidosDown from '@/assets/map/patrulla_servicios_establecidos_down.png'
import patrullaServiciosEstablecidosDownSelected from '@/assets/map/patrulla_servicios_establecidos_down_selected.png'

import patrullaMedidasCautelaresUp from '@/assets/map/patrulla_medidas_cautelares_up.png'
import patrullaMedidasCautelaresUpSelected from '@/assets/map/patrulla_medidas_cautelares_up_selected.png'
import patrullaMedidasCautelaresDown from '@/assets/map/patrulla_medidas_cautelares_down.png'
import patrullaMedidasCautelaresDownSelected from '@/assets/map/patrulla_medidas_cautelares_down_selected.png'

import deviceUp from '@/assets/map/device_up.png'
import deviceDown from '@/assets/map/device_down.png'

import incidentProteccionCivil from '@/assets/map/incident_proteccion_civil.svg'
import incidentMedico from '@/assets/map/incident_medico.svg'
import incidentSeguridad from '@/assets/map/incident_seguridad.svg'
import incidentServiciosPublicos from '@/assets/map/incident_servicios_publicos.svg'
import incidentProteccionCivilSelected from '@/assets/map/incident_proteccion_civil_selected.svg'
import incidentMedicoSelected from '@/assets/map/incident_medico_selected.svg'
import incidentSeguridadSelected from '@/assets/map/incident_seguridad_selected.svg'
import incidentServiciosPublicosSelected from '@/assets/map/incident_servicios_publicos_selected.svg'
import sosIncident from '@/assets/map/sos.png'
import onstarIncident from '@/assets/map/onstar.png'
import incident from '@/assets/map/incident.png'

export default {
  camera: {
    day: {
      up: cameraUp,
      up_selected: cameraUpSelected,
      down: cameraDown,
      down_selected: cameraDownSelected,
    },
    night: {
      up: cameraUp,
      up_selected: cameraUpSelected,
      down: cameraDown,
      down_selected: cameraDownSelected,
    },
    offsetY: -35,
    offsetX: 1
  },
  taxi: {
    day: {
      up: taxiDayUp,
      up_selected: taxiDayUpSelected,
      down: taxiDayDown,
      down_selected: taxiDayDownSelected,
    },
    night: {
      up: taxiNightUp,
      up_selected: taxiNightUpSelected,
      down: taxiNightDown,
      down_selected: taxiNightDownSelected,
    },
    offsetY: -35,
    offsetX: 1
  },
  bus: {
    day: {
      up: busDayUp,
      up_selected: busDayUpSelected,
      down: busDayDown,
      down_selected: busDayDownSelected,
    },
    night: {
      up: busNightUp,
      up_selected: busNightUpSelected,
      down: busNightDown,
      down_selected: busNightDownSelected,
    },
    offsetY: -35,
    offsetX: -1
  },
  lpr: {
    day: {
      up: lprDayUp,
      up_selected: lprDayUpSeleted,
      down: lprDayDown,
      down_selected: lprDayDownSelected,
    },
    night: {
      up: lprNightUp,
      up_selected: lprNightUpSelected,
      down: lprNightDown,
      down_selected: lprNightDownSelected,
    },
    scale: 1,
    offsetY: -35,
    offsetX: 1
  },
  device: {
    municipal: {
      up: patrullaMunicipalUp,
      up_selected: patrullaMunicipalUpSelected,
      down: patrullaMunicipalDown,
      down_selected: patrullaMunicipalDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    municipalp: {
      up: policiaMunicipalUp,
      up_selected: policiaMunicipalUpSelected,
      down: policiaMunicipalDown,
      down_selected: policiaMunicipalDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    estatalp: {
      up: policiaEstatalUp,
      up_selected: policiaEstatalUpSelected,
      down: policiaEstatalDown,
      down_selected: policiaEstatalDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    ministerial: {
      up: patrullaMinisterialUp,
      up_selected: patrullaMinisterialUpSelected,
      down: patrullaMinisterialDown,
      down_selected: patrullaMinisterialDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    ministerialp: {
      up: policiaMinisterialUp,
      up_selected: policiaMinisterialUpSelected,
      down: policiaMinisterialDown,
      down_selected: policiaMinisterialDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    ambulancia: {
      up: ambulanciaUp,
      up_selected: ambulanciaUpSelected,
      down: ambulanciaDown,
      down_selected: ambulanciaDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    bombero: {
      up: bomberosUp,
      up_selected: bomberosUpSelected,
      down: bomberosDown,
      down_selected: bomberosDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    militar: {
      up: militarUp,
      up_selected: militarUpSelected,
      down: militarDown,
      down_selected: militarDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    ayudantia: {
      up: ayudantiaUp,
      up_selected: ayudantiaUpSelected,
      down: ayudantiaDown,
      down_selected: ayudantiaDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    elementoPcivil: {
      up: elementoPcivilUp,
      up_selected: elementoPcivilUpSelected,
      down: elementoPcivilDown,
      down_selected: elementoPcivilDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    car: {
      up: patrullaEstatalUp,
      up_selected: patrullaEstatalUpSelected,
      down: patrullaEstatalDown,
      down_selected: patrullaEstatalDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    arrow: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -25,
      offsetX: 1
    },
    stj: {
      up: patrullaStjUp,
      up_selected: patrullaStjUpSelected,
      down: patrullaStjDown,
      down_selected: patrullaStjDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    dif: {
      up: patrullaDifUp,
      up_selected: patrullaDifUpSelected,
      down: patrullaDifDown,
      down_selected: patrullaDifDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    pcivil: {
      up: patrullaPcivilUp,
      up_selected: patrullaPcivilUpSelected,
      down: patrullaPcivilDown,
      down_selected: patrullaPcivilDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    bronce: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -25,
      offsetX: 1
    },
    guardia_nacional: {
      up: patrullaGnacionalUp,
      up_selected: patrullaGnacionalUpSelected,
      down: patrullaGnacionalDown,
      down_selected: patrullaGnacionalDownSelected,
      scale: 1,
      offsetY: -35,
      offsetX: 1
    },
    recorridos: {
      up: patrullaRecorridosUp,
      up_selected: patrullaRecorridosUpSelected,
      down: patrullaRecorridosDown,
      down_selected: patrullaRecorridosDownSelected,
      scale: 0.20,
      offsetY: -28,
      offsetX: 1
    },
    servicios_establecidos: {
      up: patrullaServiciosEstablecidosUp,
      up_selected: patrullaServiciosEstablecidosUpSelected,
      down: patrullaServiciosEstablecidosDown,
      down_selected: patrullaServiciosEstablecidosDownSelected,
      scale: 0.20,
      offsetY: -28,
      offsetX: 1
    },
    medidas_cautelares: {
      up: patrullaMedidasCautelaresUp,
      up_selected: patrullaMedidasCautelaresUpSelected,
      down: patrullaMedidasCautelaresDown,
      down_selected: patrullaMedidasCautelaresDownSelected,
      scale: 0.20,
      offsetY: -28,
      offsetX: 1
    },
    default: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -25,
      offsetX: 1
    },
  },
  incident: {
    seguridad: incidentSeguridad,
    proteccion_civil: incidentProteccionCivil,
    medico: incidentMedico,
    servicios_publicos: incidentServiciosPublicos,
    seguridad_selected: incidentSeguridadSelected,
    proteccion_civil_selected: incidentProteccionCivilSelected,
    medico_selected: incidentMedicoSelected,
    servicios_publicos_selected: incidentServiciosPublicosSelected,
    default: incident,
    sos: sosIncident,
    uber: sosIncident,
    onstar: onstarIncident,
    scale: 1,
    offsetY: -35,
    offsetX: 1
  },
  sensor: {
    up: sensorUp,
    up_selected: sensorUpSelected,
    down: sensorDown,
    down_selected: sensorDownSelected,
    alert: sensorDown,
    alert_selected: sensorDownSelected,
    reported: sensorDown,
    reported_selected: sensorDownSelected,
    scale: 1,
    offsetY: -35,
    offsetX: 1
  },
  deviceDown,
  deviceUp
}
